import { requestGet, requestPost } from "@/server/axios.js";
import { baseURL } from "@/server/config.js";

export const getModuleList = (scene, tag, page, keyword, contentRequest) => {
  return requestGet(`${baseURL}/album/module-list-v2`, {
    position: 0,
    page,
    tag,
    scene,
    keyword: keyword,
    contentRequest: contentRequest
  });
};
export const getAlbumModuleDetail = (albumId,contentRequest) => {
  return requestGet(`${baseURL}/album/module-detail`, {
    albumId,
    planId: 0,
    contentRequest:contentRequest
  });
};
export const collectRequest = (albumId) => {
  return requestPost(`${baseURL}/collect/collect?albumId=` + albumId, {});
};

export const unCollectRequest = (albumId) => {
  return requestPost(`${baseURL}/collect/unCollect?albumId=` + albumId, {});
};

export const getCollectList = (type, page) => {
  return requestGet(`${baseURL}/album/collect-list`, {
    collectTypeDict: type,
    page: page
  });
};

export const listCompanyByAlbumId = (albumId, page) => {
  return requestGet(`${baseURL}/company/listByAlbumId`, { albumId, page });
};

export const searchAlbumListV2 = (tagMap, keyword, page, type) => {
  return requestPost(`${baseURL}/v2/home/searchAlbumListV2`, {
    tagMap: tagMap,
    keyword: keyword,
    page: page,
    type: type
  });
};
