// 添加请求拦截器
import axios from "axios";
import { Message, Loading } from "element-ui";
import cookie from "@/utils/cookie";
import Store from "@/store/index.js";
// 绑定loading在 .app-main DOM 上
const loadingInstance = Loading.service({ target: ".app-main" });
loadingInstance.close()
// 用于设定loading计数
let loadingCount = 0;

const ExpiredTime = 60;
let http = axios.create({
  timeout: ExpiredTime * 1000,
  withCredentials: true,
});
// 请求拦截
http.interceptors.request.use(
  function (config) {
    config.headers = {
      "Content-Type": "application/json",
    };
    if (cookie.getCookie("token")) {
      config.headers["Authorization"] = "Bearer " + cookie.getCookie("token");
    }
    // 每次发一次请求自增 1
    loadingCount++;
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
http.interceptors.response.use(
  function (response) {
    return errorHandler(response);
  },
  function (error) {
    // console.log('reject',error);
    errorHandler(error.response);
  }
);
// 错误处理
function errorHandler(errorResponse) {
  // 返回结果不规范，暂时这样判断
  const resStatus = errorResponse.data
    ? +errorResponse.data.code || errorResponse.status
    : errorResponse.status;

  // console.log(errorResponse, "-----",resStatus);


  // 每次发一次请求自减 1
  loadingCount--;
  if (loadingCount === 0) {
    // 当为 0 时清除掉loading状态
    setTimeout(() => {
      loadingInstance.close();
    }, 1500);
  }
  if (resStatus === 200 || resStatus === 0) {
    return errorResponse.data;
  }
  let msg = handleResStatus(resStatus);

  if (errorResponse.data) {
    msg = errorResponse.data.info;
  }
  if(resStatus !== 401){
    Message.error(msg || " ");
  }
  return Promise.reject(errorResponse);
}

// 根据 HTTP 码 返回错误提示
function handleResStatus(resStatus) {
  let msg = "";
  switch (resStatus) {
    case 301: // 301: 登录信息已过期，跳转到登录页面重新获取token
      msg = "登录信息已过期，需要重新登录";
      cookie.clearCookie("token");
      Store.dispatch("UPDATE_LOGIN_VISIABLE", true);
      Store.dispatch("UPDATE_USER_INFO", null);
      break;
    case 302: // 302: 未登录,登录信息已过期，跳转到登录页面重新获取token
      msg = "登录信息已过期，需要重新登录";
      cookie.clearCookie("token");
      Store.dispatch("UPDATE_LOGIN_VISIABLE", true);
      Store.dispatch("UPDATE_USER_INFO", null);
      break;
    case 401:
      msg = "用户权限已过期，请重新登录";
      cookie.clearCookie("token");
      Store.dispatch("UPDATE_LOGIN_VISIABLE", true);
      Store.dispatch("UPDATE_USER_INFO", null);
      break;
    case 403: // 403 token过期
      msg = "请重新登录";
      cookie.clearCookie("token");
      Store.dispatch("UPDATE_LOGIN_VISIABLE", true);
      Store.dispatch("UPDATE_USER_INFO", null);
      break;
    default:
      msg = "服务器错误，请联系管理员";
  }
  return msg;
}

export function requestGet(url, params = {}, config = {}) {
  return http.get(url, { params: params, ...config });
}

export function requestPost(url, data = {}, config = {}) {
  let formData = data;
  if (config.formData) {
    formData = new FormData();
    Object.keys(data).forEach((item) => {
      formData.append(item, data[item]);
    });
  }
  return http.post(url, formData, config);
}
