<template>
  <el-dialog
    class="jobs-login"
    title="请选择您的求职类型"
    :visible="scanVisible"
    @update:visible="onVisible"
    width="25vw"
    top="25vh"
    :close-on-click-modal="false"
    :show-close="false"
    center
  >
    <div class="button" @click="choose(item)" v-for="item in jobTypes" :key="item">{{ item }}</div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import cookie from "@/utils/cookie";
import { getLoginDetail, updateMember } from "@/apis/account";

export default {
  data() {
    return {
      jobTypes: ["校招求职", "社招求职"]
    };
  },
  computed: {
    ...mapGetters(["scanVisible", "userInfo"])
  },
  created() {
    if (cookie.getCookie("token") && !this.userInfo) {
      getLoginDetail().then(res => {
        console.log(res.data);
        this.UPDATE_USER_INFO(res.data);
        // this.UPDATE_SCAN_LOGIN_VISIABLE(!res.data.jobType);
      });
    }
  },
  methods: {
    choose(jobType) {
      this.userInfo.jobType = jobType;
      this.UPDATE_SCAN_LOGIN_VISIABLE(false);
      updateMember(this.userInfo).then(res => {
        this.UPDATE_USER_INFO(res.data);
      });
    },
    onVisible() {
      this.UPDATE_SCAN_LOGIN_VISIABLE(false);
    },
    ...mapActions(["UPDATE_SCAN_LOGIN_VISIABLE", "UPDATE_USER_INFO"])
  }
};
</script>
<style lang="scss">
.jobs-login {
  .el-dialog {
    border-radius: 8px;
  }

  .button {
    width: 215px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    border-radius: 4px;
    background: #f2f2f2;
    margin: 0 auto 20px;
    cursor: pointer;
  }

  .active {
    color: #fff;
    background: #143dce;
  }
}
</style>
