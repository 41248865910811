import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loginVisiable: false,
    wechatServiceVisible: false,
    scanVisible: false,
    mobileVerifyVisible: false,//绑定手机
    userInfo: null,
    upgradeVipVisible:false,
  },
  getters: {
    loginVisiable: (state) => state.loginVisiable,
    scanVisible: (state) => state.scanVisible,
    wechatServiceVisible: (state) => state.wechatServiceVisible,
    mobileVerifyVisible: (state) => state.mobileVerifyVisible,
    upgradeVipVisible: (state) => state.upgradeVipVisible,
    userInfo: (state) => state.userInfo,
  },
  mutations: {
    LOGIN_VISIABLE(state, bool) {
      state.loginVisiable = bool;
    },
    SCAN_LOGIN_VISIABLE(state, bool) {
      state.scanVisible = bool;
    },
    WECHAT_SERVICE_VISIBLE(state, bool) {
      state.wechatServiceVisible = bool;
    },
    UPGRADE_VIP_VISIBLE(state, bool) {
      state.upgradeVipVisible = bool;
    },
    USER_INFO(state, data) {
      state.userInfo = data;
    },
    MOBILE_VERIFY_VISIBLE(state, data) {
      state.mobileVerifyVisible = data;
    },
  },
  actions: {
    UPDATE_LOGIN_VISIABLE({ commit }, data) {
      commit("LOGIN_VISIABLE", data);
    },
    UPDATE_SCAN_LOGIN_VISIABLE({ commit }, data) {
      commit("SCAN_LOGIN_VISIABLE", data);
    },
    UPDATE_WECHAT_SERVICE_VISIBLE({ commit }, data) {
      commit("WECHAT_SERVICE_VISIBLE", data);
    },
    UPDATE_VIP_VISIBLE({ commit }, data) {
      commit("UPGRADE_VIP_VISIBLE", data);
    },
    UPDATE_USER_INFO({ commit }, data) {
      commit("USER_INFO", data);
    },
    UPDATE_MOBILE_VERIFY_VISIBLE({ commit }, data) {
      commit("MOBILE_VERIFY_VISIBLE", data);
    },
  },
});
