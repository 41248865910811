var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-container',{staticClass:"container"},[_c('header',[_c('el-row',{attrs:{"type":"flex","align":"middle","justify":"space-between"}},[_c('router-link',{attrs:{"to":{
              path: '/'
            }}},[_c('div',{staticClass:"logo"},[_c('p',{staticClass:"title"},[_vm._v("OfferTalks")]),_c('p',{staticClass:"sub-tittle"},[_vm._v("不用内推也能找到好工作")])])]),_c('el-col',{staticClass:"line hidden-md-and-down",attrs:{"span":14,"lg":15,"offset":1}},_vm._l((_vm.homeSections),function(item){return _c('router-link',{key:item.label,attrs:{"to":{
              path: item.url,
              query: {
                tag: _vm.scenes[item.tag],
              },
            }}},[_c('div',{staticClass:"home-tab"},[_c('span',{style:({'color':item.url === _vm.urlPath ? '#0052FE' : ''})},[_vm._v(_vm._s(item.label))]),_c('div',{class:[item.url === _vm.urlPath ? 'home-tab-line' : '']})])])}),1),_c('div',{staticClass:"avatar"},[_c('el-dropdown',{on:{"command":_vm.handleCommand}},[_c('router-link',{attrs:{"to":"/"}},[(_vm.userInfo)?_c('div',{staticClass:"user"},[_c('div',{staticClass:"avatar-img"},[_c('img',{staticClass:"img",attrs:{"src":_vm.userInfo.avatar?_vm.userInfo.avatar:'https://res.offertalks.cn/miniapp/default-avatar.png'}})])]):_c('div',{staticClass:"loginBtn",on:{"click":_vm.login}},[_vm._v(" 立即登录 ")])]),(_vm.userInfo)?_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"disabled":true}},[_vm._v("用户号 : "+_vm._s(_vm.userInfo.id))]),_c('el-dropdown-item',{attrs:{"divided":"","command":"logout"}},[_vm._v("退出登录")])],1):_vm._e()],1),_c('div',{staticClass:"talks",on:{"click":function($event){return _vm.goOfferTalks()}}},[_vm._v("求职陪跑")])],1)],1)],1),_c('el-drawer',{attrs:{"visible":_vm.drawer,"with-header":false,"direction":'ltr'},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('div',{staticClass:"logo drawer-logo"},[_c('p',{staticClass:"title"},[_vm._v("OfferTalks")])]),_c('el-divider'),_c('div',{staticClass:"drawer-links"},_vm._l((_vm.homeSections),function(item){return _c('router-link',{key:item.label,attrs:{"to":{
            path: item.url,
          }}},[_c('div',{staticClass:"box",style:({ background: item.bg })}),_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }