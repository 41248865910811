import { requestGet, requestPost } from "@/server/axios.js";
import { baseURL } from "@/server/config.js";

export const login = (code) => {
  return requestGet(`${baseURL}/auth/web_login`, { code });
};
export const getLoginDetail = () => {
  return requestGet(`${baseURL}/member/currentDetail`, {});
};
export const updateMember = (member) => {
  return requestPost(`${baseURL}/member/updateMember`, member);
};
export const sendVerifyCode = (mobile) => {
  return requestGet(`${baseURL}/verifyCode/sendCode`, {
    mobile
  });
};
export const sendCodeByLogin = (mobile,answerCode,loginUUID) => {
  return requestGet(`${baseURL}/verifyCode/sendCodeByLogin`, {
    mobile,answerCode,loginUUID
  });
};
export const verifyCode = (mobile, code) => {
  return requestPost(`${baseURL}/verifyCode/verify`, {
    mobile,code
  });
};
export const verifyByLogin = (mobile, code) => {
  return requestPost(`${baseURL}/verifyCode/verifyByLogin`, {
    mobile,code
  });
};
export const getLoginQrCode = () => {
  return requestGet(`${baseURL}/auth/getQrCode`,{})
}
export const checkScanLogin = (ticket) => {
  return requestGet(`${baseURL}/weixin/mp/checkLogin`,{
    ticket
  })
}
export const getQuestionCode = () => {
  return requestGet(`${baseURL}/auth/getQuestionCode`,{
  })
}

