<template>
  <el-dialog
    :visible="upgradeVipVisible"
    @update:visible="onVisible"
    width="40%"
    :close-on-click-modal="true"
    center
    class="vipDialog"
  >

    <div class="payment-vip-main-page">

      <div class="bg"/>

      <div class="payment-content">
<!--        <div class="vip-title" v-if="albumInfo">-->
<!--          {{albumInfo.type}}会员-->
<!--        </div>-->
<!--        <div v-loading="statData === null && userInfo">
          <div v-if="statData" class="statistic" >
&lt;!&ndash;            <div v-for="item in statData" class="stat-item" :key="item.type">
              <span class="data">{{ item.count }}</span>
              <span class="type">{{ item.type }}</span>
            </div>&ndash;&gt;
&lt;!&ndash;            <div class="stat-item" style="padding: 5px" >&ndash;&gt;
&lt;!&ndash;              <span class="data">会员俱乐部成员公告</span>&ndash;&gt;
&lt;!&ndash;            </div>&ndash;&gt;
          </div>

        </div>-->

        <div v-loading="prePayInfo === null && userInfo">
<!--          <div class="vip-desc-layout" v-if="prePayInfo">-->
<!--            <div v-for="item in prePayInfo.vipDescList" class="vip-desc" :key="item">-->
<!--              <div class="title">{{item[0]}}</div>-->
<!--              <div class="content">{{item[1]}}</div>-->
<!--            </div>-->
<!--          </div>-->

          <div style="text-align: center;margin-top: 10px" v-if="prePayInfo && prePayInfo.codeUrl">
            <VueQr :text="prePayInfo.codeUrl" />
            <div style="text-align: center;display: flex;justify-content: center;align-items: center;margin-top: 20px">
              <img src="https://res.offertalks.cn/miniapp/weixin_pay_icon.png" style="text-align: center;width: 50px;height: 50px;"/>
              <span style="margin-left: 10px;font-size: 18px;text-align: center">微信支付</span>
            </div>
          </div>


          <div class="payment-layout" v-if="prePayInfo">
            <div class="payment-btn">
              <span class="unit">￥</span>
              <span class="amount">{{ prePayInfo.amount / 100 }}</span>
              <span class="txt"> / {{prePayInfo.expireStr}}</span>
              <span v-if="prePayInfo.beforeAmount && prePayInfo.beforeAmount > prePayInfo.amount" class="oldAmount">￥{{prePayInfo.beforeAmount / 100}}</span>
              <span class="txt">立即扫码开通
<!--              <span v-if="albumInfo && albumInfo.type === 'OfferTalks'" >【{{albumInfo.jobTags[0]}}】</span>-->
              </span>
            </div>
          </div>

          <div v-if="!userInfo" style="height: 500px;font-size: 30px;text-align: center;color: #0052FE;margin-top: 50px">
            请先登录
          </div>
        </div>



      </div>
    </div>

  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { checkOrderStatus, createPreOrder, getStatisticData, getVipPayInfo } from "@/apis/order";
import VueQr from "vue-qr";
import { getLoginDetail } from "@/apis/account";
import { getAlbumModuleDetail } from "@/apis/module";

export default {
  data() {
    return {
      statData:null,
      centerDialogVisible: true,
      prePayInfo: null,
      timer: null,
      title:'订单活动',
      albumInfo:null
    };
  },
  components: { VueQr },
  computed: {
    ...mapGetters(["upgradeVipVisible","userInfo"])

  },
  watch: {
    upgradeVipVisible(newVal) {
      if (newVal) {
        this.getStatistic()
        // this.getVipInfo()
        this.getPayConfig();
        this.getBaseAlbumInfo(this.$route.query.albumId)
      }
    },
    userInfo(user) {
      console.log('update user ' , user)
      if (user && this.upgradeVipVisible) {
        this.getStatistic()
        this.getPayConfig()
      }
    }
  },
  methods: {
    getBaseAlbumInfo(albumId) {
      if (albumId) {
        getAlbumModuleDetail(albumId, "0").then(res => {
          console.log(res)
          this.albumInfo = res.data.album
        })
      }
    },
    getStatistic() {
      getStatisticData().then(res => {
        this.statData = res.data
      })
    },
    getVipInfo() {
      getVipPayInfo().then(res => {
        this.prePayInfo = res.data
      })
    },
    getPayConfig() {
      createPreOrder(this.$route.query.albumId).then(res => {
        this.prePayInfo = res.data;

        this.title = " " + this.prePayInfo.amount / 100;

        if (this.timer == null) {
          this.timer = setInterval(() => {
            checkOrderStatus(this.prePayInfo.orderId).then(r => {
              if (r.data === "PAY_SUCCESS") {
                //支付成功
                getLoginDetail().then(result => {
                  this.UPDATE_USER_INFO(result.data);
                  this.UPDATE_VIP_VISIBLE(false);
                });
                clearInterval(this.timer);
              }
            });
          }, 3000);
        }
      });
    },
    onVisible() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.UPDATE_VIP_VISIBLE(false);
    },
    ...mapActions(["UPDATE_VIP_VISIBLE", "UPDATE_USER_INFO"])
  }
};
</script>
<style lang="scss" scoped>
.vipDialog{
  .order-dialog {

    .order-title{
      text-align: center;
      font-size: 27px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: 400;
    }
    .order-desc{
      text-align: start;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: 400;
      line-height: 27px;
      font-weight: bold;
    }
    .row-text {
      font-size: 26px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #666666;
      line-height: 27px;
      width: 100%;
      display: flex;
      margin-top: 30px;
      justify-content: space-between;
    }


  }
  /deep/ .el-dialog {
    border-radius: 15px;
    margin-top: 10px !important;

    .el-dialog--center .el-dialog__body,
    .el-dialog__body,
    .el-dialog__header,
    .el-dialog__footer {
      padding: 0;
    }
    .el-dialog__headerbtn{
      z-index: 100;
    }
  }


  .payment-vip-main-page {
    display: flex;
    align-items: center;
    flex-direction: column;

    .bg{
      position: relative;
      width: 100%;
      height: 520px;
      border-radius: 20px;
      background: linear-gradient( 180deg, rgba(237,203,154,0.8) 0%, rgba(237,203,154,0) 100%);
    }



    .payment-content{
      z-index: 100;
      width: 550px;
      margin-top: -500px;

      .vip-title{
        background: linear-gradient( 180deg, #6B4600 0%, #4E2C00 100%);
        font-size: 36px;
        text-align: center;
        font-weight: 600;
        /* 设置文本的颜色为白色，以便于背景渐变的颜色遮罩 */
        color: white;
        /* 设置文本的背景为线性渐变 */
        //background: linear-gradient(45deg, blue, red);
        /* 使用 blend-mode 实现文本颜色与背景渐变颜色的混合 */
        -webkit-background-clip: text;
        background-clip: text;
        /* 使用 color 作为遮罩，实际看到的是背景渐变 */
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        /* 防止文本阴影遮挡背景渐变 */
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;

      }

      .statistic {
        width: 100%;
        display: flex;
        margin: 10px 20px;
        padding: 10px 25px;
        background: linear-gradient(288deg, #7F4400 0%, #5B3500 100%, #5B3500 100%);
        border-radius: 15px;

        .stat-item {
          flex: 1;
          justify-content: center;
          justify-items: center;
          text-align: center;
          display: flex;
          flex-direction: column;

          .data {
            font-size: 26px;
            font-family: DINAlternate, DINAlternate;
            font-weight: bold;
            color: #FFCF7C;
            line-height: 24px;
          }

          .type {
            margin-top: 10px;
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-style: normal;
            color: #FFCF7C;
            line-height: 15px;
          }
        }
      }

      .vip-desc-layout {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0 20px 0 20px;
        border-radius: 15px;
        background-color: #fff;
        padding: 10px 20px;
        border: 1px solid #D0D0D0;

        .vip-desc {
          margin-bottom: 15px;
          font-family: PingFangSC, PingFang SC;
          color: #000000;
          line-height: 1.5;

          .title{
            font-weight: 600;
            font-size: 24px;
          }
          .content{
            font-size: 18px;
            font-weight: 400;
          }
        }
      }

      .payment-layout {
        margin: 20px 20px 20px 20px;

        width: 100%;
        //background-color: #fff;
        height: 50px;

        .payment-btn {
          display: flex;
          text-align: center;
          border-radius: 50px;
          height: 50px;
          background-color: #F2CA8B;
          justify-content: center;
          align-items: center;

          .unit {
            font-size: 18px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            color: #462A21;
            line-height: 19px;
          }

          .amount {
            font-size: 24px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            color: #462A21;
            line-height: 34px;
          }

          .oldAmount{
            font-size: 18px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            margin-left: 6px;
            line-height: 34px;
            color: grey;
            text-decoration: line-through;
          }

          .txt {
            font-size: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            margin-left: 5px;
            color: #462A21;
            line-height: 21px;
          }
        }
      }

    }


  }
}

</style>
