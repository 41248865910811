import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";
import htmlToPdf from "@/utils/htmlToPdf";

Vue.config.productionTip = false;
Vue.use(ElementUI, { size: "small" });
Vue.use(htmlToPdf)
new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    console.log('env:',process.env)
  },
  beforeCreate() {
    Vue.prototype.$bus = this
  }
}).$mount("#app");
