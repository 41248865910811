import { render, staticRenderFns } from "./ShowWechatServiceDialog.vue?vue&type=template&id=4ec3af88&scoped=true&"
import script from "./ShowWechatServiceDialog.vue?vue&type=script&lang=js&"
export * from "./ShowWechatServiceDialog.vue?vue&type=script&lang=js&"
import style0 from "./ShowWechatServiceDialog.vue?vue&type=style&index=0&id=4ec3af88&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ec3af88",
  null
  
)

export default component.exports