<template>
  <el-container class="container">
    <header>
      <el-row type="flex" align="middle" justify="space-between">
        <router-link
          v-bind:to="{
              path: '/'
            }"
        >
        <div class="logo">
          <p class="title">OfferTalks</p>
          <p class="sub-tittle">不用内推也能找到好工作</p>
        </div>
        </router-link>
        <el-col :span="14" :lg="15" :offset="1" class="line hidden-md-and-down">
          <!-- :to="item.url" -->
          <router-link
            v-bind:to="{
              path: item.url,
              query: {
                tag: scenes[item.tag],
              },
            }"
            v-for="item in homeSections"
            :key="item.label"
          >
            <!--            <div class="box" :style="{ background: item.bg }">-->
            <!--&lt;!&ndash;              <img :src="require('@/assets/images/' + item.icon)" />&ndash;&gt;-->
            <!--            </div>-->
            <div class="home-tab">
              <span :style="{'color':item.url === urlPath ? '#0052FE' : ''}">{{ item.label }}</span>
              <div :class="[item.url === urlPath ? 'home-tab-line' : '']"></div>
            </div>


          </router-link>
        </el-col>
<!--        <img class="vip-icon" src="https://res.offertalks.cn/miniapp/icon-vip.png"-->
<!--             @click="upgradeVip"-->
<!--             :style="{filter:(!userInfo || !userInfo.vipTypes) ? 'grayscale(100%)':''}"-->
<!--        />-->
        <div class="avatar">
          <el-dropdown @command="handleCommand">
            <router-link to="/">
              <div class="user" v-if="userInfo">
                <div class="avatar-img">
                  <img class="img"
                       :src="userInfo.avatar?userInfo.avatar:'https://res.offertalks.cn/miniapp/default-avatar.png'" />
                </div>
              </div>
              <div v-else class="loginBtn" @click="login">
                立即登录
              </div>
            </router-link>
            <el-dropdown-menu slot="dropdown" v-if="userInfo">
              <el-dropdown-item :disabled="true">用户号 : {{userInfo.id}}</el-dropdown-item>
              <el-dropdown-item divided command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="talks" @click="goOfferTalks()">求职陪跑</div>
        </div>


      </el-row>
    </header>
    <el-drawer :visible.sync="drawer" :with-header="false" :direction="'ltr'">
      <div class="logo drawer-logo">
        <p class="title">OfferTalks</p>
      </div>
      <el-divider></el-divider>
      <div class="drawer-links">
        <router-link
          v-bind:to="{
            path: item.url,
          }"
          v-for="item in homeSections"
          :key="item.label"
        >
          <div class="box" :style="{ background: item.bg }">
            <!--            <img :src="require('@/assets/images/' + item.icon)" />-->
          </div>
          {{ item.label }}
        </router-link>
      </div>
    </el-drawer>
  </el-container>
</template>

<script>
import cookie from "@/utils/cookie";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      drawer: false,
      tagType:'all',
      urlPath:'',
      scenes: {
        公司: "",
        简历: "",
        面试: "",
        项目: ""
      },
      homeSections: [
        {
          label: "机会超多",
          tag: "schedule",
          url: "/home/schedule",
          needLogin:false,
          bg: "rgba(121, 72, 234, 0.1)",
        },
        {
          label: "Offer多多",
          tag: "all",
          url: "/home/all",
          needLogin:false,
          bg: "rgba(121, 72, 234, 0.1)",
        },
        {
          label: "简历神器",
          tag: "person",
          url: "/home/person",
          needLogin:false,
          bg: "rgba(121, 72, 234, 0.1)",
        },
        {
          label: "面试必过",
          tag: "user",
          url: "/home/user",
          needLogin:false,
          bg: "rgba(121, 72, 234, 0.1)",
        },



        // {
        //   label: "我的",
        //   tag: "myRecommend",
        //   url: "/myRecommend",
        //   needLogin:true,
        //   bg: "rgba(121, 72, 234, 0.1)",
        // },
        // {
        //   label: "全部行业",
        //   tag: "行业",
        //   url: "/module/list/行业",
        //   icon: "project.png",
        //   bg: "rgba(121, 72, 234, 0.1)",
        // },
        // {
        //   label: "精选公司",
        //   tag: "公司",
        //   url: "/module/list/公司",
        //   icon: "company.png",
        //   bg: "rgba(212, 48, 48, 0.1)",
        // },
        // {
        //   label: "首页",
        //   tag: "home",
        //   url: "/module/list/home",
        //   icon: "resume.png",
        //   bg: "rgba(0, 186, 173, 0.1)",
        // },
        // {
        //   label: "我的",
        //   url: "/myself",
        // },
        // {
        //   label: "精选面试",
        //   tag: "面试",
        //   url: "/module/list/面试",
        //   icon: "interview.png",
        //   bg: "rgba(42, 130, 228, 0.1)",
        // },

        // {
        //   label: "会员权益",
        //   url: "/membership",
        //   icon: "vip.png",
        //   bg: "rgba(255, 195, 0, 0.1)",
        // },
      ]
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  watch: {
    $route: {
      handler() {
        this.tagType = this.$route.params.type
        this.urlPath = this.$route.path
        console.log('route,',this.$route.path)
      }
    }
  },
  mounted() {
    // console.log("userInfo", this.userInfo);
  },
  methods: {
    login(){
      this.UPDATE_LOGIN_VISIABLE(true)
    },
    goOfferTalks() {
      this.UPDATE_WECHAT_SERVICE_VISIBLE(true);
    },
    handleCommand(commond) {
      if (commond === "myself") {
        this.$router.push({
          path: "/myself"
        });
      } else if (commond === "logout") {
        this.logout();
      }
    },
    logout() {
      cookie.clearCookie("token");
      this.UPDATE_USER_INFO(null);
      this.$router.push({
        path: "/"
      });
    },
    upgradeVip() {
      // this.UPDATE_VIP_VISIBLE(true);
    },
    ...mapActions(["UPDATE_WECHAT_SERVICE_VISIBLE", "UPDATE_VIP_VISIBLE", "UPDATE_USER_INFO","UPDATE_LOGIN_VISIABLE"])
  }
};
</script>

<style lang="scss">
.container {
  margin-bottom: 10px;

  .logo {
    width: 190px;
    font-size: 36px;
    font-weight: 700;
    color: #0052FE;

    .logo-i {
      font-size: 60px;
    }

    .sub-tittle {
      margin-top: 3px;
      font-size: 20px;
      font-weight: 500;
      color: #0052FE;
    }
  }

  header {
    padding: 0 20px;
    width: 100%;
    height: 88px;
    background: rgba(255, 255, 255, 1);
    overflow: hidden;

    .el-row {
      height: 100%;
    }

    .vip-icon {
      width: 185px;
      height: 43px;
      margin-right: 20px;
      //cursor: pointer;
    }

    .avatar {
      display: flex;
      align-items: center;
      justify-content: end;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #303133;

      .loginBtn{
        color: #0052FE;
        font-size: 20px;
        width: 100px;
        font-weight: 600;
        text-decoration: underline;
        text-align: center;
      }

      .user {
        text-align: center;

        .text {
          height: 28px;
          line-height: 28px;
        }
      }

      .avatar-img {
        display: flex;
        align-items: center;
        justify-content: center;

        .img {
          width: 56px;
          height: 56px;
          border-radius: 56px;
        }
      }

      .talks {
        cursor: pointer;
        margin-left: 40px;
        width: 189px;
        height: 67.75px;
        line-height: 67.75px;
        border-radius: 4px;
        background: #0052FE;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        vertical-align: top;
        font-family: PingFangSC-Semibold, PingFang SC;
      }
    }

    .line {
      height: 100%;

      a {
        display: inline-flex;
        align-items: center;
        margin-right: 40px;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0px;
        height: 100%;
        text-align: center;
      }

      .box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 6px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #303133;

        img {
          width: 26px;
          height: 26px;
        }
      }
    }

  }

  .drawer-links {
    margin-top: 10px;

    a {
      display: flex;
      align-items: center;
      color: #111019;
      font-size: 54px;
      padding: 30px 35px;
    }

    .box {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 25px;
      padding: 10px;
    }
  }

  .drawer-logo {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 120px;
    font-size: 58px;
  }

  .el-divider--horizontal {
    margin: 0;
  }

  .home-tab {
    font-size: 27px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    line-height: 33px;
    width: 140px;
    height: 100%;
    background: #FFFFFF;
    cursor: pointer;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
  }

  .home-tab-line {
    width: 80px;
    height: 5px;
    position: absolute;
    bottom: 0;
    left: 30px;
    background: #0052FE;
  }
}
</style>
