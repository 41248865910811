<template>
  <el-dialog
    :visible="mobileVerifyVisible"
    @update:visible="onVisible"
    width="500px"
    title="绑定手机号"
    top="25vh"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    center
  >
    <div style="text-align: center;">

      <div v-if="step === 1">
        <el-input type="tel"
                  v-model="mobile" placeholder="请输入手机号" class="input-mobile"></el-input>

        <el-button type="primary" class="sendCodeBtn"
                   @click="sendCode"
                   :disabled="disableSendCode">
          {{ timer === null ? "发送验证码" : (countdown + "s") }}
        </el-button>
      </div>

      <div v-else>
        <span>验证码已通过短信发送至{{ mobile }}</span>
        <el-input class="inputCode" type="text" maxlength="6" v-model="code">
          <div slot="suffix" class="codeTime">{{ countdown }}s</div>
        </el-input>
        <el-button type="primary" class="sendCodeBtn" @click="verifyCode">登录</el-button>
        <div>
          <el-button type="text" style="margin-top: 20px;font-size: 16px" @click="step=1">返回</el-button>
        </div>
      </div>


    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { sendVerifyCode, verifyCode } from "@/apis/account";

export default {
  data() {
    return {
      mobile: null,
      step: 1,
      code: null,
      countdown: 60,
      timer: null
    };
  },
  computed: {
    disableSendCode() {
      if (this.mobile && this.mobile.length >= 11) {
        if (this.timer === null) {
          return false;
        }
      }
      return true;
    },
    ...mapGetters(["mobileVerifyVisible", "userInfo"])
  },
  watch: {
    userInfo(newVal) {
      if (newVal && !newVal.mobile) {
        //未绑定
        this.UPDATE_MOBILE_VERIFY_VISIBLE(true);
      }
    }
  },
  methods: {
    onVisible() {
      this.UPDATE_MOBILE_VERIFY_VISIBLE(false);
    },
    sendCode() {
      sendVerifyCode(this.mobile).then(res => {
        if (res.code === 0) {
          this.step = 2;
          this.startCountdown();
        }
      });
    },
    verifyCode() {
      verifyCode(this.mobile, this.code).then(res => {
        if (res.code === 0) {
          clearInterval(this.timer);
          this.timer = null;
          this.userInfo.mobile = this.mobile;
          this.UPDATE_USER_INFO(this.userInfo);
          this.UPDATE_MOBILE_VERIFY_VISIBLE(false);
        }
      });
    },
    startCountdown() {
      this.countdown = this.$options.data.call(this).countdown;
      if (this.timer == null) {
        this.timer = setInterval(() => {
          this.countdown--;
          if (this.countdown <= 0) {
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    ...mapActions(["UPDATE_MOBILE_VERIFY_VISIBLE", "UPDATE_USER_INFO"])
  }
};
</script>
<style lang="scss" scoped>

.el-dialog {
  .input-mobile {
    width: 480px;
    height: 67px !important;
    line-height: 67px !important;
    background: #FFFFFF;
    border-radius: 5px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;

    .el-input__inner {
      height: 67px !important;
      line-height: 67px !important;
    }
  }

  .inputCode {
    margin-top: 30px;
    width: 480px;

    .el-input__inner {
      border: none;
      border-bottom: 3px solid #0857FE;
      border-radius: 0;
      line-height: 67px !important;
      height: 67px !important;
    }

    .codeTime {
      height: 67px;
      line-height: 67px;
      text-align: center;
    }
  }

  .sendCodeBtn {
    height: 67px;
    width: 480px;
    margin-top: 25px;
    background: #0857FE;
    border-radius: 5px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 33px;
  }
}


</style>
