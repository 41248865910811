<template>
  <div id="app">
    <Header ref="header" />
    <el-container class="app-main">
      <router-view :key="$route.fullPath"/>
    </el-container>
    <Footer />
    <QRLogin />
    <JobLogin />
    <ShowUpgradeVipDialog/>
    <MobileVerifyDialog/>
    <ShowWechatServiceDialog/>
  </div>
</template>

<script>
import Header from "@/components/layous/Header.vue";
import Footer from "@/components/layous/Footer.vue";
import JobLogin from "@/views/system/ChooseJobsTypeDialog.vue";
import MobileVerifyDialog from "@/views/system/MobileVerifyDialog.vue";
import QRLogin from "@/views/system/QRLogin.vue";
import ShowUpgradeVipDialog from "@/views/system/ShouUpgradeVipDialog.vue";
// import watermark from "@/utils/watermark";
import { mapGetters } from "vuex";
import ShowWechatServiceDialog from "@/views/system/ShowWechatServiceDialog";

export default {
  components: {
    ShowWechatServiceDialog,
    Header,
    Footer,
    QRLogin,
    ShowUpgradeVipDialog,
    JobLogin,
    MobileVerifyDialog
  },
  data() {
    return {};
  },
  created() {
  },
  mounted() {
    console.log('mounted user',this.userInfo)
    // if(this.userInfo){
    //   watermark.set("Offertalks.cn", this.userInfo.name);
    // }else{
    //   watermark.set("Offertalks.cn");
    // }
  },
  watch: {
    userInfo(user) {
      console.log('update user ' , user)
      if (user && user.name) {
        // watermark.reset("Offertalks.cn", user.name);
      }
    }
  },
  computed: {
    isMenu() {
      return ["MemberShip", "OfferTalks", "Project"].includes(this.$route.name);
    },
    ...mapGetters(["userInfo"])
  }
};
</script>

<style lang="scss">
@import url("./assets/reset.scss");

body {
  background-color: #f6f7fa;
}

#app {
  .app-main {
    overflow: hidden;
  }

  .offer-talk_main {
    width: calc(100% - 166px);
    height: calc(100vh - 88px);
    padding-top: 0;
    padding: 0 0 0 20px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .vip-btn {
    font-size: 21px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 29px;
    padding: 12px 64px;
    border-radius: 10px;
    background: linear-gradient(270deg, #008AFF 0%, #0052FE 100%);
    margin-left: 40px;
    margin-top: 20px;
    margin-right: 20px;
  }
}
</style>
