<template>
  <el-dialog
    :visible="loginVisiable"
    @update:visible="onVisible"
    width="40vw"
    :height="'auto'"
    :close-on-click-modal="false"
    center
    title="账号登录"
    class="login"
  >

    <el-tabs :stretch="true" @tab-click="selectLoginTab" value="loginByWechat">
      <el-tab-pane label="微信扫码" name="loginByWechat">
        <div style="text-align: center;height: 350px">
          <div style="font-size: 18px;line-height: 26px;margin-bottom: 20px">
            请使用微信扫码登录
          </div>
          <img style="width: 300px;height: 300px" :src="authUrl" />
        </div>
      </el-tab-pane>

      <el-tab-pane label="手机号登录" name="loginByMobile">
        <div style="text-align: center;padding: 0 40px">
          <el-input type="tel"
                    prefix-icon="el-icon-phone"
                    size="medium"
                    v-model="mobile" placeholder="   请输入手机号" class="input-mobile" />
          <div style="display: flex;">
            <el-input type="text"
                      size="medium"
                      prefix-icon="el-icon-question"
                      maxlength="3"
                      v-model="answerCode" placeholder="   验证码" class="input-mobile" />
            <img :src="codeUrl" @click="getCode" class="mobileCodeImg" />
          </div>

          <div style="display: flex;margin-top: 20px">
            <el-input class="inputCode" type="text"
                      size="medium"
                      prefix-icon="el-icon-mobile"
                      maxlength="6" v-model="mobileCode" />
            <el-button type="primary" class="sendCodeBtn"
                       @click="sendCode"
                       :disabled="disableSendCode">
              {{ codeTimer === null ? "发送验证码" : (countdown + "s") }}
            </el-button>
          </div>

          <el-button type="primary" class="sendCodeBtn"
                     style="width: 100%;margin-top: 40px;margin-left: 0 !important;"
                     :disabled="disableLoginBtn"
                     @click="verifyCodeAndLogin">登录
          </el-button>
        </div>
      </el-tab-pane>


    </el-tabs>



  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  checkScanLogin,
  getLoginDetail,
  getLoginQrCode,
  getQuestionCode,
  sendCodeByLogin,
  verifyByLogin
} from "@/apis/account";
import cookie from "@/utils/cookie";

export default {
  data() {
    return {
      centerDialogVisible: true,
      // appid: "wxc1d8770b7fcac9e6",
      authUrl: null,
      scanTimer: null,
      codeTimer:null,
      ticket: null,
      mobile: null,
      answerCode: null,
      codeUrl: null,
      loginUUID: null,
      countdown: 60,
      mobileCode: null
    };
  },
  computed: {
    disableSendCode() {
      if (this.mobile && this.mobile.length >= 11) {
        if (this.codeTimer === null && this.answerCode) {
          return false;
        }
      }
      return true;
    },
    disableLoginBtn() {
      if (this.mobile && this.mobile.length >= 11 && this.answerCode && this.mobileCode) {
        return false;
      }
      return true;
    },
    ...mapGetters(["loginVisiable"])
  },
  watch: {
    loginVisiable(newVal) {
      if (newVal) {
        this.getLatestUrl();
      }
    }
  },
  methods: {
    selectLoginTab(tab) {
      if (tab.name === "loginByMobile") {
        this.getCode();
      }
    },
    getCode() {
      getQuestionCode().then(res => {
        this.codeUrl = res.data.img;
        this.loginUUID = res.data.uuid;
      });
    },
    getLatestUrl() {
      getLoginQrCode().then(res => {
        console.log("getLoginQrCode", res);
        this.authUrl = res.data.picUrl;
        this.ticket = res.data.token;
        this.startGetResult();
      });
      // const redirect = encodeURIComponent("https://www.offertalks.cn/wxAuth_callback" +
      //   "?to=" + encodeURIComponent(window.location.href)
      //   + "&env=" + process.env.NODE_ENV
      //   + "&origin=" + encodeURIComponent(window.location.origin)
      //   + "&from_wx=1"
      // );
      // return "https://open.weixin.qq.com/connect/qrconnect?appid=" + this.appid
      //   + "&scope=snsapi_login"
      //   + "&redirect_uri=" + redirect
      //   + "&response_type=code"
      //   + "#wechat_redirect";
    },
    startGetResult() {
      if (this.scanTimer == null) {
        this.scanTimer = setInterval(() => {
          this.getUserInfo();
        }, 2000);
      }
    },
    getUserInfo() {
      checkScanLogin(this.ticket).then(res => {
        console.log("checkScanLogin", res);
        if (res && res.data) {
          this.UPDATE_LOGIN_VISIABLE(false);
          cookie.setCookie({
            token: res.data.token
          }, 7);
          getLoginDetail().then(result => {
            this.UPDATE_USER_INFO(result.data);
          });
          clearInterval(this.scanTimer);
        }
      });
    },
    onVisible() {
      this.UPDATE_LOGIN_VISIABLE(false);
    },
    sendCode() {
      sendCodeByLogin(this.mobile, this.answerCode,this.loginUUID).then(res => {
        if (res.code === 0) {
          this.startCountdown();
        }
      });
    },
    startCountdown() {
      if (this.codeTimer == null) {
        this.codeTimer = setInterval(() => {
          this.countdown--;
          if (this.countdown <= 0) {
            clearInterval(this.codeTimer);
            this.codeTimer = null;
          }
        }, 1000);
      }
    },
    verifyCodeAndLogin() {
      verifyByLogin(this.mobile, this.mobileCode).then(res => {
        if (res.code === 0) {
          clearInterval(this.codeTimer);
          this.codeTimer = null;

          this.UPDATE_LOGIN_VISIABLE(false);
          cookie.setCookie({
            token: res.data.token
          }, 7);
          getLoginDetail().then(result => {
            this.UPDATE_USER_INFO(result.data);
          });
        }
      });
    },
    ...mapActions(["UPDATE_LOGIN_VISIABLE", "UPDATE_USER_INFO"])
  }
};
</script>
<style lang="scss" scoped>

/deep/ .el-input__icon {
  height: 100%;
  display: flex;
  align-items: center;
}

.login {
  .el-dialog {
    .el-dialog__header {
      border-bottom: 1px solid #e9e9eb;
    }
    .el-tabs__content{
      overflow: auto;


    }

    .el-tabs--border-card {
      min-height: 300px;
    }


  }

  .ml-10 {
    margin-left: 10px;
  }


  .scan {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    color: rgba(96, 98, 102, 1);

    .el-button--text {
      color: rgba(48, 49, 51, 1);
    }
  }

  .weixin {
    margin-bottom: 20px;
    margin-top: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(144, 147, 153, 1);

    .el-button--text {
      color: rgba(48, 49, 51, 1);
    }
  }

  p {
    text-align: center;
  }

  .btn {
    margin-left: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .i-text {
    margin-left: 6px;
  }

  .refresh {
    animation: spin 1s infinite linear;
  }

  @-moz-keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @-o-keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


  /deep/ .input-mobile {
    width: 100%;
    height: 67px !important;
    line-height: 67px !important;
    background: #FFFFFF;
    border-radius: 5px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    margin-top: 20px;

    .el-input__inner {
      height: 67px !important;
      line-height: 67px !important;
    }
  }

  .mobileCodeImg {
    margin-left: 20px;
    width: 200px;
    margin-top: 20px;
    height: 70px;
  }

  /deep/ .inputCode {
    height: 67px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    line-height: 67px;

    .el-input__inner {
      height: 67px !important;
      line-height: 67px !important;
    }
  }

  .sendCodeBtn {
    height: 67px;
    width: 300px;
    background: #0857FE;
    margin-left: 20px;
    border-radius: 5px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 33px;
  }
}
</style>
