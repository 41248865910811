import { requestGet } from "@/server/axios.js";
import { baseURL } from "@/server/config.js";

export const createPreOrder = (albumId) => {
  return requestGet(`${baseURL}/order/createVipOrder`, {
    albumId:albumId
  });
};
export const checkOrderStatus = (orderId) => {
  return requestGet(`${baseURL}/order/checkPayStatus`, {
    orderId
  });
};

export const getVipPayInfo = () => {
  return requestGet(`${baseURL}/order/getVipPayInfo`, {})
}
export const getStatisticData = () => {
  return requestGet(`${baseURL}/v2/vip/getStatistic`, {})
}