import Vue from "vue";
import VueRouter from "vue-router";

// 解决两次访问相同路由地址报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(locationParams) {
  return originalPush.call(this, locationParams).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home/schedule"
  },
  {
    path: "/membership",
    name: "MemberShip",
    component: () => import("../views/membership/index.vue")
  },
  {
    path: "/home/:type",
    name: "HomeV2",
    component: () => import("../views/v2/HomeV2.vue")
  },
  {
    path:'/album/detail',
    name:'AlbumDetail',
    component:()=>import('../views/v2/AlbumDetail.vue')
  },
  {
    path:'/myRecommend',
    name:'MyRecommend',
    component:()=>import('../views/v2/MyRecommend.vue')
  },
  {
    path: "/offertalks",
    name: "OfferTalks",
    component: () => import("../views/offertalks/index.vue")
  },
  {
    path: "/myself",
    name: "Myself",
    component: () => import("../views/member/Myself.vue")
  },
  {
    path: "/module/:scene",
    name: "ModuleIndex",
    component: () => import("../views/module/ModuleIndex.vue"),
    children: [
      {
        path: "/module/list/:scene",
        name: "ModuleDataList",
        component: () => import("../views/module/ModuleDataList.vue")
      },
      {
        path: "/module/detail/:scene/:albumId",
        name: "ModuleDetail",
        component: () => import("../views/module/ModuleDetailV2.vue")
      }
    ]
  },
  {
    path: "/module_detail/:albumId",
    name: "ModuleDetail",
    component: () => import("../views/module/ModuleDetailV2.vue")
  },
  {
    path: "/wxAuth_callback",
    name: "WxAuthCallback",
    component: () => import("../views/system/WxAuthCallback.vue")
  },
  {
    path: "*", // 此处需特别注意置于最底部
    redirect: "/home/all"
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
