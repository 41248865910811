<template>
  <el-dialog
    :visible="wechatServiceVisible"
    @update:visible="onVisible"
    width="500px"
    title="立即添加二维码，咨询花姐"
    :close-on-click-modal="false"
    center
  >
    <div style="text-align: center">
      <img src="https://res.offertalks.cn/miniapp/wechat-service-qrcode.png" style="width: 400px"/>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name:"ShowWechatServiceDialog",
  data() {
    return {
      centerDialogVisible: true
    };
  },
  computed: {
    ...mapGetters(["wechatServiceVisible"])
  },
  watch: {},
  methods: {
    onVisible() {
      this.UPDATE_WECHAT_SERVICE_VISIBLE(false);
    },
    ...mapActions(["UPDATE_WECHAT_SERVICE_VISIBLE"])
  }
};
</script>
<style lang="scss" scoped>
.login {
  .el-dialog {
    .el-dialog__header {
      border-bottom: 1px solid #e9e9eb;
    }
  }

  .ml-10 {
    margin-left: 10px;
  }

  img {
    margin: 28px auto 20px auto;
    width: 200px;
    height: 200px;
    display: inherit;
  }

  .scan {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    color: rgba(96, 98, 102, 1);

    .el-button--text {
      color: rgba(48, 49, 51, 1);
    }
  }

  .weixin {
    margin-bottom: 20px;
    margin-top: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(144, 147, 153, 1);

    .el-button--text {
      color: rgba(48, 49, 51, 1);
    }
  }

  p {
    text-align: center;
  }

  .btn {
    margin-left: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .i-text {
    margin-left: 6px;
  }

  .refresh {
    animation: spin 1s infinite linear;
  }

  @-moz-keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @-o-keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
